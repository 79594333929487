import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {Logger} from '../logger.service';
import {Auth} from 'aws-amplify';
import { Observable, firstValueFrom } from 'rxjs';
import { UsersService } from '@app/pages/users/users.service';
import { AuthenticationService } from './authentication.service';

const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router, private userService: UsersService, private authService: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const requiredPermission = route.data['requiredPermission'];
    return firstValueFrom(this.authService.currentUser())
      .then(async ([authUser, profile]) => {
        if (requiredPermission) {
          if (!this.authService.hasPermission(requiredPermission)) {
            this.router.navigate(['/sign-in']);
          }
        }
        return true;
      })
      .catch(() => {
        log.debug('user is NOT authenticated');
        this.router.navigate(['/sign-in']);
        return false;
      });
  }
}
