<div [ngClass]="{'show': showOptions}" class="options transition">
  <button (click)="showOptions = !showOptions" class="options-icon mat-elevation-z0" mat-raised-button>
    <!-- <mat-icon>palette</mat-icon> -->
    <mat-icon>settings</mat-icon>
  </button>
  <mat-card fxLayout="column" fxLayoutAlign="space-between center">
    <span (click)="changeTheme('omnitect')" class="skin-icon omnitect"></span>
    <span (click)="changeTheme('black')" class="skin-icon black"></span>
    <span (click)="changeTheme('deals')" class="skin-icon deals"></span>
    <span (click)="changeTheme('places')" class="skin-icon places"></span>
    <!-- <span class="skin-icon green" (click)="changeTheme('green')"></span>  -->
    <span (click)="changeTheme('blue')" class="skin-icon blue"></span>
    <!-- <span class="skin-icon red" (click)="changeTheme('red')"></span>  -->
    <!-- <span class="skin-icon pink" (click)="changeTheme('pink')"></span>  -->
    <span (click)="changeTheme('purple')" class="skin-icon purple"></span>
    <span (click)="changeTheme('grey')" class="skin-icon grey"></span>
    <!-- <button mat-mini-fab color="warn" (click)="settings.rtl = !settings.rtl" matTooltip="LTR / RTL">
        <mat-icon>swap_horiz</mat-icon>
    </button> -->
  </mat-card>
</div>
