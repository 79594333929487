import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '@app/core';
import {CognitoUser} from '@aws-amplify/auth';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {CryptoService} from '@app/core/services/crypto.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  loginForm: UntypedFormGroup;
  rememberMe = false;

  constructor(private router: Router,
              private authService: AuthenticationService,
              private cryptoService: CryptoService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {

    this.rememberMe = Boolean(localStorage.getItem('login_remember'));

    if (!this.rememberMe) {
      this.rememberMe = false;
    }

    this.loginForm = this.createLoginForm();

    if (this.rememberMe) {

      const pwd = this.cryptoService.decrypt3DES(localStorage.getItem('login_pwd'), '@mni#ec1!');
      this.loginForm.controls.username.setValue(localStorage.getItem('login_user'));
      this.loginForm.controls.password.setValue(pwd);
    }

  }

  createLoginForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      username: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }

  public onLoginFormSubmit(): void {

    if (this.loginForm.valid) {

      this.signIn(this.loginForm.get('username').value, this.loginForm.get('password').value);

    }
  }

  signIn(username: string, password: string) {

    this.authService.signInAuth(username, password)
      .then((user: CognitoUser | any) => {

        if (this.rememberMe) {

          const pwd = this.cryptoService.encrypt3DES(password, '@mni#ec1!');

          localStorage.setItem('login_remember', String(true));
          localStorage.setItem('login_user', username);
          localStorage.setItem('login_pwd', pwd);
        } else {
          localStorage.removeItem('login_remember');
          localStorage.removeItem('login_user');
          localStorage.removeItem('login_pwd');
        }

        this.router.navigate(['/']);
      })
      .catch((error: any) => {
        switch (error.code) {
          case 'UserNotConfirmedException':
            this.router.navigate(['sign-in/confirm']);
            break;
          default:
            this.snackBar.open(error.message, '×', {panelClass: 'error', verticalPosition: 'top', duration: 3000});
            break;
        }
      });

  }

  rememberMeChanged(checked: boolean) {
    this.rememberMe = checked;
  }

}
