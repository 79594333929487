import {Injectable} from '@angular/core';
import * as forge from 'node-forge';

@Injectable({
  providedIn: 'root'
})

export class CryptoService {
  constructor() {
  }

  encrypt3DES(input, key) {
    let md5Key = forge.md.md5.create();
    md5Key.update(key);
    const md5KeyStr = md5Key.digest().toHex();

    const cipher = forge.cipher.createCipher('3DES-ECB', md5KeyStr.substring(0, 24));
    cipher.start();
    cipher.update(forge.util.createBuffer(input));
    cipher.finish();
    return cipher.output.getBytes();
  }

  decrypt3DES(input, key) {
    let md5Key = forge.md.md5.create();
    md5Key.update(key);
    const md5KeyStr = md5Key.digest().toHex();
    const decipher = forge.cipher.createDecipher('3DES-ECB', md5KeyStr.substring(0, 24));
    decipher.start();

    const inputEx = forge.util.createBuffer(input);
    decipher.update(inputEx);
    decipher.finish();
    return decipher.output.getBytes();
  }

}
