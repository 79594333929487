import {Component, OnInit} from '@angular/core';
import {AppSettings, Settings} from './app.settings';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loading = false;
  public settings: Settings;

  constructor(private http: HttpClient,
              private appSettings: AppSettings
  ) {

    this.settings = this.appSettings.settings;

  }

  async ngOnInit() {

  }

}
