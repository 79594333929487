import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxSpinnerModule} from 'ngx-spinner';
import {PagesComponent} from './pages/pages.component';
import {SidenavMenuComponent} from './theme/components/sidenav-menu/sidenav-menu.component';
import {OptionsComponent} from './theme/components/options/options.component';
import {FooterComponent} from './theme/components/footer/footer.component';

import {AppSettings} from './app.settings';

import {OverlayContainer} from '@angular/cdk/overlay';
import {CustomOverlayContainer} from './theme/utils/custom-overlay-container';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {BreadcrumbComponent} from './theme/components/breadcrumb/breadcrumb.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {SignInModule} from './pages/sign-in/sign-in.module';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {ShortcutsComponent} from './theme/components/shortcuts/shortcuts.component';
import {NotFoundModule} from './pages/not-found/not-found.module';
import {ErrorModule} from './pages/error/error.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {ForgotPasswordModule} from './pages/forgot-password/forgot-password.module';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';

@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    SidenavMenuComponent,
    OptionsComponent,
    FooterComponent,
    BreadcrumbComponent,
    ShortcutsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    SignInModule,
    ForgotPasswordModule,
    NgxSpinnerModule,
    AppRoutingModule,
    CoreModule,

    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatAutocompleteModule,
    NotFoundModule,
    ErrorModule
  ],
  providers: [
    AppSettings,
    {provide: OverlayContainer, useClass: CustomOverlayContainer},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
// }
