<div fxLayout="row" fxLayoutAlign="start center">
  <a (click)="backClicked()" color="accent" mat-button>
    <mat-icon>keyboard_arrow_left</mat-icon>
  </a>
  <div class="font-size-24">{{ title }}</div>
</div>

<!-- <ol class="breadcrumb">
    <li *ngFor="let breadcrumb of breadcrumbs">
        <span [routerLink]="breadcrumb.url" routerLinkActive="router-link-active">
        {{ breadcrumb.label }}
        </span>
    </li>
</ol> -->
<!-- <div fxLayout="row wrap" *ngIf="router.url != '/'">
    <div fxFlex="100">
        <mat-card fxLayout="row" class="breadcrumb light-block">
            <div *ngFor="let breadcrumb of breadcrumbs; let i = index;" class="breadcrumb-item" fxLayout="row" fxLayoutAlign="start center">
                <a [hidden]="i == (breadcrumbs.length - 1)" [routerLink]="[breadcrumb.url]">{{breadcrumb.name}}</a>
                <span [hidden]="i != (breadcrumbs.length - 1)" class="breadcrumb-title active"><b>{{breadcrumb.name}}</b></span>
            </div>
        </mat-card>
    </div>
</div> -->
