<div class="full-page text-center" fxLayout="column">
  <img class="image" src="assets/images/construction.png">
  <h1 class="text-muted mt-60">Sorry, this page is not available yet.</h1>
  <!-- <mat-card class="content text-center">
      <mat-card-content>
          <img src="assets/images/construction.png" class="image">
          <h1 class="text-muted">Sorry, this page is not available yet.</h1>
      </mat-card-content>
  </mat-card> -->
</div>
