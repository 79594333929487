<div *ngFor="let menu of parentMenu" class="menu-item">
  <a (click)="onClick(menu.id)" *ngIf="menu.routerLink && !menu.hasSubMenu"
     [id]="'menu-item-'+menu.id" [routerLinkActiveOptions]="{exact:true}"
     [routerLink]="[menu.routerLink]" fxLayout="row" fxLayoutAlign="start center"
     mat-button routerLinkActive="active-link">
    <span class="menu-title">{{menu.title}}</span>
  </a>
  <a (click)="onClick(menu.id)" *ngIf="menu.href && !menu.subMenu"
     [attr.href]="menu.href || ''" [attr.target]="menu.target || ''"
     [id]="'menu-item-'+menu.id" fxLayout="row"
     fxLayoutAlign="start center" mat-button>
    <span class="menu-title">{{menu.title}}</span>
  </a>
  <a (click)="onClick(menu.id)" *ngIf="menu.hasSubMenu"
     [id]="'menu-item-'+menu.id" [routerLink]="[menu.routerLink]"
     fxLayout="row"
     fxLayoutAlign="start center" mat-button>
    <span class="menu-title">{{menu.title}}</span>
    <mat-icon class="menu-expand-icon transition-2">arrow_drop_down</mat-icon>
  </a>

  <div *ngIf="menu.hasSubMenu" [id]="'sub-menu-'+menu.id" class="sub-menu">
    <app-sidenav-menu [menuItems]="menuItems" [menuParentId]="menu.id"></app-sidenav-menu>
  </div>
</div>
