import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';

export interface IBreadCrumb {
  label: string;
  url: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})

export class BreadcrumbComponent implements OnInit {

  public breadcrumbs: IBreadCrumb[];

  title = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {

    this.router.events
      .subscribe(event => {

        if (event instanceof NavigationEnd) {

          const routerUrl = router.url;

          if (routerUrl && typeof routerUrl === 'string') {
            const routerList = routerUrl.slice(1).split('/');
            const data = this.activatedRoute.snapshot.routeConfig.children.find(child => child.path === routerList[0]).data;
            if (data) {
              this.title = data.breadcrumb;
            } else {
              this.title = '';
            }
          }
        }
      });

  }

  ngOnInit() {

  }

  backClicked() {

    this.location.back(); // <-- go back to previous location on cancel
  }

}
