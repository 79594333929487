import { TierLevels } from './tierlevel.model';

export class User {
  id: number;
  memberroleid: number;
  memberstatusid: number;
  reportsto_memberid: number | null;
  statustext: string;
  statusid: number;
  warranteeexpiresdays: number;
  inventorycolor: string;
  statusdate: Date;
  statusnote: string;
  memberrolename: string;
  supervisor: string;
  fullname: string;
  employeenumber: string;
  signinonly: boolean;
  isactive: boolean;
  iscontractor: boolean;
  isconsultant: boolean;
  noinventory: boolean;
  isovertimeexempt: boolean;
  isprojectmanager: boolean;
  isresourceeligible: boolean;
  issupervisor: boolean;
  inventorytop: number;
  inventoryleft: number;
  firstname: string;
  lastname: string;
  username: string;
  nickname: string;
  teamname: string;
  addressline1: string;
  addressline2: string;
  city: string;
  state: string;
  zip: string;
  emailaddress: string;
  phonenumber: string;
  faxnumber: string;
  note: string;
  statusupdateddate: Date;
  employeestartdate: Date;
  employeehiredate: Date;
  billablehours: number;
  text1: string;
  text2: string;
  createddate: Date;
  modifieddate: Date;
  createdby: string;
  modifiedby: string;
  cognitoId: string;
  teamid: number;
  totalcount: number;
  availability: Availability;
  preferredCadTechLevelId?: number;
  primaryColor: string;
  secondaryColor: string;
  tierLevelIds: number[];
  tierLevels: TierLevels[];
  cadTechLevelId: number;
  qaReviewAssignmentPriority?: number;

  constructor() {}
}

export class UserWithPermission extends User {
  memberPermissions: UserPermissions[];
}

export class MemberStatus {
  id: number;
  memberid: number;
  statusid: number;
  statusnote: string;
  createddate: Date;
  statusdate: Date;

  constructor() {}
}

export class MemberStatusId {
  constructor(public id: number, public status: string) {}
}

export interface Availability {
  perDayCAD: AvailabilityPerDay;
  perDayQA: AvailabilityPerDay;
  exceptions: AvailabilityException[];
}

export interface AvailabilityWeekly {
  perDayCAD: AvailabilityPerDay;
  perDayQA: AvailabilityPerDay;
}

export interface AvailabilityPerDay {
  type?: string;
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
  sunday: number;
}

export interface AvailabilityException {
  id?: number;
  startDate: Date;
  endDate: Date;
  reason: string;
  cadHours: number;
  qaHours: number;
  memberId?: number;
  memberIds?: number[];
}

export interface CadTechLevel {
  id: number;
  name: string;
  factor: number;
}

export enum UserPermissions {
  CalendarScheduleRead = 'CalendarSchedule::Read',
  CalendarScheduleWrite = 'CalendarSchedule::Write',
  TaskDashboardRead = 'TaskDashboard::Read',
  TaskDashboardWrite = 'TaskDashboard::Write',
  TaskDashboardReadOthers = 'TaskDashboard::ReadOthers',
  TaskDashboardWriteOthers = 'TaskDashboard::WriteOthers',
}

export interface OverbookedUsersAndDates {
  [userId: number]: Date[];
}

export enum UserRole {VirtualTeam = 7};