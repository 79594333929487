import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '@app/core';
import {Router} from '@angular/router';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  codeForm = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required]),
  });

  resetForm = new UntypedFormBuilder().group({
    code: ['', Validators.compose([Validators.required])],
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: ['', Validators.required]
  }, {validator: matchingPasswords('password', 'confirmPassword')});

  username = '';
  errorMsg = '';

  constructor(private authService: AuthenticationService,
              private router: Router,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
  }

  codeSubmit() {

    this.authService.sendResetPasswordCode(this.codeForm.controls.username.value)
      .then(data => {
        console.log('code response: ', data);
        this.username = this.codeForm.controls.username.value;
      })
      .catch(err => {
        console.log(err);
        this.errorMsg = err.message;
      });

  }

  resetSubmit() {

    this.authService.resetPassword(
      this.username,
      this.resetForm.controls.code.value,
      this.resetForm.controls.password.value)
      .then(data => {

        this.snackBar.open(this.username + ' password has been reset!', '×',
          {panelClass: 'success', verticalPosition: 'top', duration: 5000});

        this.router.navigate(['/sign-in']);
      })
      .catch(err => {
        console.log(err);
        this.errorMsg = err.message;
      });

  }

}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
  return (group: UntypedFormGroup) => {
    const password = group.controls[passwordKey];
    const passwordConfirmation = group.controls[passwordConfirmationKey];
    if (password.value !== passwordConfirmation.value) {
      return passwordConfirmation.setErrors({mismatchedPasswords: true});
    }

  };
}
