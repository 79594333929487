<div class="sign-in">
  <mat-card class="login-card">
    <mat-card-title class="text-center text-muted">
      Welcome To Omnitect
      <img class="mt-16" src="assets/images/DBDG_Splash_Logo.png">
    </mat-card-title>

    <form (ngSubmit)="onLoginFormSubmit()" [formGroup]="loginForm">
      <mat-card-content>

        <mat-form-field appearance="outline" class="w-100 mt-2">
          <mat-label>User Name</mat-label>
          <input formControlName="username" matInput placeholder="User Name">
          <mat-error *ngIf="loginForm.controls.username.errors?.required">Username is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100 xmt-1">
          <mat-label>Password</mat-label>
          <input formControlName="password" matInput placeholder="Password" type="password">
          <mat-error *ngIf="loginForm.controls.password.errors?.required">Password is required</mat-error>
          <mat-error *ngIf="loginForm.controls.password.hasError('minlength')">Password isn't long enough, minimum of 6
            characters
          </mat-error>
        </mat-form-field>

        <mat-checkbox (change)="rememberMeChanged($event.checked)" [checked]="rememberMe" color="primary">Remember Me
        </mat-checkbox>
      </mat-card-content>
      <mat-card-actions>
        <!-- <button mat-raised-button color="primary" class="w-100" type="submit" [disabled]="loginForm.invalid && loginForm.dirty">
            Login
        </button> -->
        <div fxLayout="column" fxLayoutAlign="center center">
          <button [disabled]="loginForm.invalid" class="mat-elevation-z6" color="primary" mat-fab>
            <mat-icon>exit_to_app</mat-icon>
          </button>
          <a [routerLink]="['/forgot-password']" class="mt-2" mat-button>Forgot Password</a>
        </div>

        <!-- <button class="w-100" type="submit" [disabled]="loginForm.invalid && loginForm.dirty">
            Login
        </button> -->
      </mat-card-actions>
    </form>

  </mat-card>

</div>
