import {NgModule, Optional, SkipSelf} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {HttpService} from './http/http.service';
import {AmplifyService} from 'aws-amplify-angular';
import {LoaderInterceptor} from './http/loader.interceptor';

import {AuthInterceptor} from './http/auth.interceptor';
import {FormService} from './services/form.service';
import {SharedDataService} from './services/shared-data.service';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import { NotificationService } from './services/notification.service';

@NgModule({
  imports: [
    HttpClientModule,
    RouterModule,
    MatDialogModule
  ],
  providers: [
    AmplifyService,
    AuthInterceptor,
    LoaderInterceptor,
    FormService,
    SharedDataService,
    NotificationService,
    {
      provide: HttpClient,
      useClass: HttpService
    },
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
