import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core';
import packageJson from '../../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  year = new Date().getFullYear();
  database = '';
  env: any;
  version = packageJson.version;

  constructor(private authService: AuthenticationService) {
  }

  ngOnInit(): void {

    this.authService.authState.subscribe(user => {

      switch (user?.memberroleid) {
        case 6:
          this.authService.config().subscribe(c => {
            this.env = JSON.parse(c.environment);
            this.database = c.database;
          });
          break;
        default:
          break;
      }
    });
  }

}
