import {Injectable} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormControlBase} from './form-control-base';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor() {
  }

  createFormGroup(controls: FormControlBase<string>[], model: any = null) {

    const group: any = {};

    controls.forEach(control => {
      group[control.key] = control.required ? new UntypedFormControl(control.value || '', Validators.required)
        : new UntypedFormControl(control.value || '');
    });
    const formGroup = new UntypedFormGroup(group);

    if (model) {
      this.bindModelToForm(model, formGroup);
    }

    return formGroup;

  }

  public bindModelToForm(model: any, form: UntypedFormGroup) {

    const keys = Object.keys(form.controls);
    const modelKeys = Object.keys(model);

    keys.forEach(key => {
      if (modelKeys.includes(key)) {
        form.controls[key].setValue(model[key]);
      } else {
        form.controls[key].setValue(null);
      }

      form.controls[key].valueChanges
        .subscribe(newValue => {
            model[key] = newValue;
          }
        );
    });

  }

}
