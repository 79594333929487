import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';

import {environment} from '@env/environment';
import {Logger} from '../logger.service';
import {Router} from '@angular/router';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(private injector: Injector,
              private snackbar: MatSnackBar) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          const router = this.injector.get(Router);

          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            errorMessage = `Client side Error: ${error.error.message}`;
          } else {
            errorMessage = `Server side Error Code: ${error.error.message}`;
          }

          console.error('http error message: ', error.message);

          if (environment.production) {
          } else {
            log.error('Error Interceptor - Request error', errorMessage);
          }
          this.snackbar.open(error.message, 'x', {panelClass: 'error', verticalPosition: 'top', duration: 5000});

          return throwError(errorMessage);

        })
      );
  }
}
