<footer class="bg-primary footer">
  <div class="copyright secondary-color theme-container" fxLayout="row" fxLayout.xs="column"
       fxLayoutAlign="space-between center">
    <p ngClass.xs="mt-1">Copyright © {{ year }} All Rights Reserved</p>
    <div *ngIf="env">
      <p>{{env.EnvironmentName}} - {{this.database}}</p>
    </div>
    <p>Version {{version}}</p>
  </div>
</footer>
