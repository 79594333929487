import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './core';
import { ErrorComponent } from './pages/error/error.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PagesComponent } from './pages/pages.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { UserPermissions } from './core/models/user.model';

export const routes: Routes = [
  { path: 'sign-in', component: SignInComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { breadcrumb: '' },
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
        data: { breadcrumb: 'USERS' },
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'clients',
        loadChildren: () => import('./pages/builders/builders.module').then(m => m.BuildersModule),
        data: { breadcrumb: 'BUILDERS' },
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
        data: { breadcrumb: 'SETTINGS' },
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'hot-leads',
        loadChildren: () => import('./pages/hot-leads/hot-leads.module').then(m => m.HotLeadsModule),
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'time-sheet',
        loadChildren: () => import('./pages/timesheets/timesheets.module').then(m => m.TimesheetsModule),
        data: { breadcrumb: 'TIME SHEETS' },
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'invoices',
        loadChildren: () => import('./pages/invoices/invoices.module').then(m => m.InvoicesModule),
        data: { breadcrumb: 'INVOICES' },
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'payments',
        loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule),
        data: { breadcrumb: 'PAYMENTS' },
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'reports',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
        data: { breadcrumb: 'REPORTS' },
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'vendors',
        loadChildren: () => import('./pages/vendors/vendors.module').then(m => m.VendorsModule),
        data: { breadcrumb: 'VENDORS' },
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'communities',
        loadChildren: () => import('./pages/communities/communities.module').then(m => m.CommunitiesModule),
        data: { breadcrumb: 'COMMUNITIES' },
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'municipalities',
        loadChildren: () => import('./pages/municipalities/municipalities.module').then(m => m.MunicipalitiesModule),
        data: { breadcrumb: 'MUNICIPALITIES' },
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'references',
        loadChildren: () => import('./pages/references/references.module').then(m => m.ReferencesModule),
        data: { breadcrumb: 'REFERENCES' },
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'contacts',
        loadChildren: () => import('./pages/contacts/contacts.module').then(m => m.ContactsModule),
        data: { breadcrumb: 'CONTACTS' },
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'divisions',
        loadChildren: () => import('./pages/divisions/divisions.module').then(m => m.DivisionsModule),
        data: { breadcrumb: 'DIVISIONS' },
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'plans',
        loadChildren: () => import('./pages/plans/plans.module').then(m => m.PlansModule),
        data: { breadcrumb: 'PLANS' },
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'utilities',
        loadChildren: () => import('./pages/utilities/utilities.module').then(m => m.UtilitiesModule),
        data: { breadcrumb: 'UTILITIES' },
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'jobsv3',
        loadChildren: () => import('./pages/jobsv3/jobsv3.module').then(m => m.Jobsv3Module),
        data: { breadcrumb: 'JOBS v3' },
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'resourcing',
        loadChildren: () => import('./pages/resourcing/resourcing.module').then(m => m.ResourcingModule),
        data: { breadcrumb: 'Resourcing', requiredPermission: UserPermissions.CalendarScheduleRead },
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'task-dashboard',
        loadChildren: () => import('./pages/task-dashboard/task-dashboard.module').then(m => m.TaskDashboardModule),
        data: { breadcrumb: 'Task dashboard' },
      },
      { path: 'error', component: ErrorComponent },
      { path: '**', component: NotFoundComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
