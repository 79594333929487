import {Observable} from 'rxjs';

export class FormField<T> {
  value: T;
  key: string;
  controlType: string;
  inputType: string;
  label: string;
  placeHolder: string;
  required: boolean;
  validator: string;
  order: number;
  displayColumn: string;
  valueColumn: string;
  data: Observable<any>;
  pipe: string;
  pipeParams: string;
  filterColumn: string;
  dataEndpoint: string;
  selectedItems: Array<any>;
  allowSelectall: boolean;
  disabled: boolean;

  constructor(
    options: {
      value?: T;
      key?: string;
      controlType?: string;
      inputType?: string;
      label?: string;
      placeHolder?: string;
      required?: boolean;
      validator?: string;
      order?: number;
      displayColumn?: string;
      data?: Observable<any>;
      pipe?: string;
      pipeParams?: string;
      filterColumn?: string;
      dataEndpoint?: string;
      selectedItems?: Array<any>;
      allowselectall?: boolean;
      disabled?: boolean;
    } = {}
  ) {
    this.value = options.value;
    this.key = options.key || '';
    this.controlType = options.controlType || '';
    this.inputType = options.inputType || '';
    this.label = options.label || '';
    this.placeHolder = options.placeHolder || '';
    this.required = !!options.required;
    this.validator = options.validator || '';
    this.order = options.order === undefined ? 1 : options.order;
    this.displayColumn = options.displayColumn || '';
    this.data = options.data || null;
    this.pipe = options.pipe || '';
    this.dataEndpoint = options.dataEndpoint || '';
    this.pipeParams = options.pipeParams || '';
    this.filterColumn = options.filterColumn || '';
    this.selectedItems = options.selectedItems || [];
    this.allowSelectall = !!options.required;
    this.disabled = !!options.disabled;
  }
}
//   }
