import {Component, Input, OnInit} from '@angular/core';
import {SidenavMenuService} from './sidenav-menu.service';
import {AuthenticationService} from '@app/core';
import { User, UserWithPermission } from '@app/core/models/user.model';
import { environment } from '@env/environment';

@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
  providers: [SidenavMenuService],
})
export class SidenavMenuComponent implements OnInit {
  @Input() menuItems;
  @Input() menuParentId;
  parentMenu: Array<any>;

  constructor(private sidenavMenuService: SidenavMenuService, private authService: AuthenticationService) {}

  async ngOnInit() {
    const user = (await this.authService.getProfile()) as UserWithPermission;

    if (user.signinonly) {
      this.menuItems = this.sidenavMenuService.getSidenavMenuLimitedItems();
    } else {
      this.menuItems = await this.sidenavMenuService.getSidenavMenuItems();

      this.menuItems = this.menuItems.filter(item => {
        let allowed = (item.memberRoles === null || item.memberRoles.includes(user.memberroleid)) &&
          (item.memberPermission == null || this.authService.hasPermission(item.memberPermission));

        if (item.isDevelopment && environment.production) {
          allowed = false;
        }

        return allowed;
      });

      this.parentMenu = this.menuItems.filter(item => item.parentId === this.menuParentId);
    }
  }

  onClick(menuId) {
    this.sidenavMenuService.toggleMenuItem(menuId);
    this.sidenavMenuService.closeOtherSubMenus(this.menuItems, menuId);
  }
}
