<mat-sidenav-container>

  <mat-sidenav #sidenav [opened]="sidenavOpen" class="sidenav xmat-elevation-z6" mode="side">
    <button (click)="sidenav.close()" class="close" color="warn" fxHide="false" fxHide.gt-sm mat-icon-button>
      <mat-icon color="warn">close</mat-icon>
    </button>
    <img alt="logo" class="sidenav-logo" src="/assets/images/DBDG_Splash_Logo.png">
    <div class="divider"></div>
    <app-sidenav-menu [menuItems]="sidenavMenuItems" [menuParentId]="0"></app-sidenav-menu>
  </mat-sidenav>
  <mat-toolbar color="primary" class="header-container">

    <mat-toolbar-row fxLayoutAlign="space-between center">
      <!-- <span fxHide="false" fxHide.gt-sm> -->
      <div fxLayout="row">
        <a (click)="sidenav.toggle()" class="sidenav-toggle" mat-icon-button>
          <mat-icon>menu</mat-icon>
        </a>
        <app-breadcrumb></app-breadcrumb>
      </div>
      <div fxLayout="row">
        <app-shortcuts class="mr-48"></app-shortcuts>

        <a #accountMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="accountMenu" class="mr-3" mat-icon-button>
          <mat-icon>person</mat-icon>
          <!-- <span fxShow="false" fxShow.gt-sm>account</span>  -->
          <!-- <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon> -->
        </a>

        <mat-menu #accountMenu="matMenu" [overlapTrigger]="false" class="app-dropdown account" xPosition="before">

                    <span (mouseleave)="accountMenuTrigger.closeMenu()">
                        <div class="user-info" fxLayout="row" fxLayoutAlign="space-around center">
                            <!-- <img src="assets/images/others/user.jpg" alt="user-image" width="50"> -->
                            <p>{{profile?.username}}</p>
                        </div>
                        <div class="divider"></div>
                        <a mat-menu-item routerLink="/account">
                            <mat-icon class="mat-icon-sm">settings</mat-icon>
                            <span>Profile</span>
                        </a>
                        <div class="divider"></div>
                        <a (click)="onSignout()" mat-menu-item>
                            <mat-icon class="mat-icon-sm">power_settings_new</mat-icon>
                            <span>Sign Out</span>
                        </a>
                    </span>
        </mat-menu>

        <!-- <app-options></app-options> -->

      </div>

    </mat-toolbar-row>
  </mat-toolbar>

  <div class="theme-container main">
    <router-outlet></router-outlet>
  </div>

  <app-options class="app-options-container"></app-options>

  <app-footer *ngIf="!router.url.includes('resourcing')"></app-footer>

</mat-sidenav-container>
