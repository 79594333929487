import {AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {SharedDataService} from '../services/shared-data.service';

export class CustomValidators extends Validators {
  constructor(private dataService: SharedDataService) {
    super();
  }

  static negativeNumber(control: UntypedFormControl) {
    if (!control || !control.value) {
      return null;
    }

    if (control.value < 0) {
      return { negativeNumber: true };
    }
  }

  static ValidateHours(control: AbstractControl) {
    const mod = control.value % 0.25;

    if (mod === 0) {
      return null;
    } else {
      return { validHours: true };
    }
  }

  static ValidateNotZeroHours(control: AbstractControl) {
    if (control.value === 0) {
      return { validHours: false };
    } else {
      return null;
    }
  }

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password').value; // get password from our password form control
    const confirmPassword: string = control.get('confirmPassword').value; // get password from our confirmPassword form control
    if (password !== confirmPassword) {
      control.get('confirmPassword').setErrors({ noPasswordMatch: true });
    }
  }

  static usernameValidator(control: AbstractControl) {
    const username: string = control.get('username').value; // get password from our password form control
    let regex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$');

    let valid = regex.test(username);

    if (!valid) {
      regex = new RegExp('^[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}$');
      valid = regex.test(username);
    }

    if (!valid) {
      control.get('username').setErrors({ usernameInvalid: true });
    }
  }

  static emailValidator(control: AbstractControl) {
    if (!control || !control.value) {
      return null;
    }

    const regex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$');

    if (!regex.test(control.value)) {
      control.setErrors({ emailInvalid: true });
    }
  }

  static multipleEmailValidator(control: AbstractControl): ValidationErrors | null {
    if (!control || !control.value) {
      return null;
    }

    const emails = control.value.split(',').map((email: string) => email.trim());
    const regex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    const invalidEmails = emails.filter(email => !regex.test(email));

    if (invalidEmails.length > 0) {
      return { invalidEmails: true };
    }

    return null;
  }

  static phoneValidator(control: AbstractControl) {
    if (!control || !control.value) {
      return null;
    }

    const phone: string = control.value; // get password from our password form control
    const regex = new RegExp('^[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}$');

    const valid = regex.test(phone);

    if (!valid) {
    }
  }

  static variableNameValidator(control: UntypedFormControl) {
    if (!control || !control.value) {
      return null;
    }

    if (control.value.includes(' ')) {
      return { variableNameValidator: true };
    }
  }
}
