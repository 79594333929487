<div class="sign-in">
  <mat-card class="login-card">
    <mat-card-title class="text-center text-muted">
      Omnitect Password Reset
      <img class="mt-16" src="assets/images/DBDG_Splash_Logo.png">
    </mat-card-title>

    <form *ngIf="!username" [formGroup]="codeForm">
      <mat-card-content>

        <mat-form-field appearance="outline" class="w-100">
          <input formControlName="username" matInput placeholder="Username">
          <mat-error *ngIf="codeForm.get('username').hasError('required')">
            Username is required
          </mat-error>
        </mat-form-field>

      </mat-card-content>

      <mat-card-actions fxLayoutAlign="center center">
        <button (click)="codeSubmit()" [disabled]="codeForm.invalid" class="mat-elevation-z6" color="primary"
                mat-raised-button>
          Send Reset Code
        </button>
      </mat-card-actions>
    </form>

    <form *ngIf="username" [formGroup]="resetForm">
      <mat-card-content>

        <div fxLayout="column">
          <div>A reset code for {{ username }} has been emailed to you. Enter the code and new password.</div>
          <mat-form-field appearance="outline" class="w-100 mt-2">
            <input formControlName="code" matInput placeholder="Code" required>
            <mat-error *ngIf="resetForm.get('code').hasError('required')">
              Code is required
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-100">
            <input formControlName="password" matInput placeholder="New Password" required type="password">
            <mat-error *ngIf="resetForm.get('password').hasError('required')">
              New password is required
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Confirm Password</mat-label>
            <input formControlName="confirmPassword" matInput placeholder="Confirm Password" required type="password">
            <mat-error *ngIf="resetForm.controls.confirmPassword.errors?.required">Confirm Password is required
            </mat-error>
            <mat-error *ngIf="resetForm.controls.confirmPassword.hasError('mismatchedPasswords')">Passwords do not
              match
            </mat-error>
          </mat-form-field>
        </div>


      </mat-card-content>

      <mat-card-actions fxLayoutAlign="center center">
        <button (click)="resetSubmit()" [disabled]="resetForm.invalid" class="mat-elevation-z6" color="primary"
                mat-raised-button>
          Reset Password
        </button>
      </mat-card-actions>
    </form>

    <div class="text-red text-center">{{ errorMsg }}</div>

  </mat-card>

</div>

<!-- <div id="forgot-password" fxLayout="column">

    <div id="forgot-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="forgot-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/sbs/handshake-100.png">
            </div>

            <div class="title">RECOVER YOUR PASSWORD</div>

            <form name="forgotPasswordForm" [formGroup]="forgotPasswordForm" novalidate>

                <mat-form-field>
                    <input matInput placeholder="Email" formControlName="email">
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
                        Email is required
                    </mat-error>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button class="submit-button" color="accent"
                        aria-label="SEND RESET LINK" [disabled]="forgotPasswordForm.invalid"
                        (click)="submit()">
                    SEND RESET LINK
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/auth/login'">Go back to login</a>
            </div>

        </div>

    </div>

</div> -->
