import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SidenavMenuService} from '@app/theme/components/sidenav-menu/sidenav-menu.service';
import {AuthenticationService} from '@app/core';
import {Router} from '@angular/router';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [SidenavMenuService],
})
export class PagesComponent implements OnInit, OnDestroy {
  public sidenavMenuItems: Array<any>;
  profile: any = null;
  @ViewChild('sidenav', { static: true }) sidenav: any;
  sidenavOpen = false;
  sidenavMode = 'over';

  constructor(
    private sidenavMenuService: SidenavMenuService,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.authService.authState.pipe(untilDestroyed(this)).subscribe(data => {
      this.profile = data;
    });

    this.showHideMenu();
  }

  ngOnDestroy() {}

  onSignout() {
    this.authService.signOut().then(() => {
      this.router.navigate(['/sign-in']);
    });
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    this.showHideMenu();
  }

  showHideMenu() {
    if (window.innerWidth < 960) {
      this.sidenavOpen = false;
      this.sidenavMode = 'over';
    } else {
      this.sidenavOpen = true;
      this.sidenavMode = 'side';
    }
  }
}
