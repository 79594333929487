import { Injectable } from "@angular/core";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";

@Injectable()
export class NotificationService {

    constructor(private readonly snackBar: MatSnackBar) {}

    success(message: string) {
        this.snackBar.open(message, '×',{ panelClass: 'success', verticalPosition: 'top', duration: 3000 });
    }

    error(message: string) {
        this.snackBar.open(message, '×',{ panelClass: 'error', verticalPosition: 'top', duration: 5000 });
    }
}