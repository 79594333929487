import {Injectable} from '@angular/core';
import { BehaviorSubject, Observable, combineLatestWith, filter, from, of } from 'rxjs';
import { AmplifyService } from 'aws-amplify-angular';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { User, UserPermissions, UserWithPermission } from '../models/user.model';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public signedIn = false;
  public profile: UserWithPermission;

  private _authState: BehaviorSubject<any> = new BehaviorSubject(null);
  authState: Observable<User> = this._authState.asObservable();

  constructor(private http: HttpClient, private amplifyService: AmplifyService, private router: Router) {
    this.amplifyService.authStateChange$.subscribe(async authState => {
      this.signedIn = authState.state === 'signedIn';

      console.log('aws cognito user is: ', authState.state);
      console.log('aws cognito user is: ', authState.state);

      if (this.signedIn) {
        sessionStorage.clear();
        sessionStorage.setItem('username', authState.user.username);

        this.profile = (await this.getProfile()) as UserWithPermission;

        sessionStorage.setItem('memberid', this.profile.id.toString());

        if (this.profile && this.profile.isactive) {
          this._authState.next(this.profile);
        } else {
          sessionStorage.removeItem('username');
          this.profile = undefined;
          Auth.signOut()
            .then(data => this.router.navigate(['/sign-in']))
            .catch(err => console.log(err));
        }
      }
    });
  }

  public async getProfile() {
    return await this.http.cache(false).get('members/me').toPromise();
  }

  public hasPermission(permission: UserPermissions): boolean {
    return this.profile.memberPermissions.includes(permission);
  }

  public currentUser() {
    return from(Auth.currentAuthenticatedUser()).pipe(
      combineLatestWith(this._authState),
      filter(([user, authState]) => user !== null && authState !== null),
    );
  }

  public getUserId(): number {
    return this.profile.id;
  }
  // async getProfile(username: string) {
  //   return await this.http
  //     .cache(true)
  //     .get('members/username', {
  //       params: new HttpParams().set('username', username),
  //     })
  //     .toPromise();
  // }

  async signUp(username: string, password: string, email: string) {
    const emp = await Auth.signUp({
      username,
      password,
      attributes: {
        email,
      },
    });

    return emp;
  }

  register(username: string, password: string, email: string): Promise<any> {
    const user = { username, password, email };
    return new Promise((resolve, reject) => {
      return this.http.post('authentication/register', user).subscribe(data => {
        if (data) {
        }
        return of(data);
      });
    });
  }

  config(): Observable<any> {
    return this.http.get('authentication/config');
  }

  signInAuth(username: string, password: string): Promise<CognitoUser | any> {
    return new Promise((resolve, reject) => {
      Auth.signIn(username, password)
        .then((user: CognitoUser | any) => {
          resolve(user);
        })
        .catch((error: any) => reject(error));
    });
  }

  signOut(): Promise<any> {
    return Auth.signOut().then(() => (this.signedIn = false));
  }

  sendResetPasswordCode(username: string) {
    return Auth.forgotPassword(username);
  }

  resetPassword(username: string, code: string, newPassword: string) {
    return Auth.forgotPasswordSubmit(username, code, newPassword);
  }
}
