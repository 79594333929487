import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {FormControlBase} from '@app/core/services/form-control-base';
import {TextboxControl} from '@app/core/services/form-textbox';
import {User} from '@app/core/models/user.model';

export interface UserQuery {
  search: string;
  registration: Date;
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  public filter = new BehaviorSubject<string>('');
  userChanged = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
  }

  updateInventoryPosition(inventoryType: string, id: number, left: number, top: number) {

    let url = 'memberinventories/position/';

    if (inventoryType) {
      url = 'memberinventories/types/position/';
    }

    console.log('url: ', url);

    return this.http.get(url + id.toString()
      + '/' + left.toString() + '/' + top.toString());
  }
  getUser(id: any) {
    return this.http.get<User>('members/' + id);
  }

  async getUserByUsername(username: string) {

    return await this.http.cache(true).get('members/username', {
      params: new HttpParams()
        .set('username', username)
    }).toPromise();

  }

  add(user: User) {
    return this.http.post('members', user);
  }

  update(user: User) {
    return this.http.put('members', user);
  }

  delete(id: any) {
    return this.http.delete('members/' + id);
  }
  getUserFormControls() {

    const controls: FormControlBase<string>[] = [

      new TextboxControl({
        key: 'firstname',
        label: 'First name',
        required: true,
        order: 1
      }),

      new TextboxControl({
        key: 'lastname',
        label: 'Last name',
        required: true,
        order: 2
      }),

      new TextboxControl({
        key: 'emailaddress',
        label: 'Email',
        type: 'email',
        order: 3
      }),

      new TextboxControl({
        key: 'phonenumber',
        label: 'Phone',
        type: 'phone',
        order: 4
      }),

      new TextboxControl({
        key: 'addressline1',
        label: 'Address',
        type: 'street',
        order: 5
      }),

      new TextboxControl({
        key: 'addressline2',
        label: 'Address 2',
        type: 'address',
        order: 6
      }),

      new TextboxControl({
        key: 'city',
        label: 'City',
        type: 'city',
        order: 7
      }),

      new TextboxControl({
        key: 'state',
        label: 'State',
        type: 'state',
        order: 8
      }),

      new TextboxControl({
        key: 'zip',
        label: 'Zip',
        type: 'zip',
        order: 9
      }),
    ];
    return controls.sort((a, b) => a.order - b.order);

  }

}
