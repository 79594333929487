import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {SidenavMenu} from './sidenav-menu.model';
import {sidenavMenuLimitedItems} from './sidenav-menu-limited';
import {HttpClient} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';
import {Appsetting} from '@app/pages/settings/app-settings/app-settings.models';
import {SharedDataService} from '@app/core/services/shared-data.service';

@Injectable()
export class SidenavMenuService {

  constructor(private location: Location,
              private dataService: SharedDataService,
              private http: HttpClient) {
  }

  async getSidenavMenuItems(): Promise<SidenavMenu[]> {

    const menu = await lastValueFrom(this.dataService.appsettings('navigation')) as Appsetting[];

    if (menu.length === 1) {
      return JSON.parse(menu[0].settingvalue);
    }
    return null;

  }

  public getSidenavMenuLimitedItems(): Array<SidenavMenu> {
    return sidenavMenuLimitedItems;
  }

  public expandActiveSubMenu(menu: Array<SidenavMenu>) {
    const url = this.location.path();
    const routerLink = decodeURIComponent(url);
    const activeMenuItem = menu.filter(item => item.routerLink === routerLink);
    if (activeMenuItem[0]) {
      let menuItem = activeMenuItem[0];
      while (menuItem.parentId !== 0) {
        const parentMenuItem = menu.filter(item => item.id === menuItem.parentId)[0];
        menuItem = parentMenuItem;
        this.toggleMenuItem(menuItem.id);
      }
    }
  }

  public toggleMenuItem(menuId) {
    const menuItem = document.getElementById('menu-item-' + menuId);
    const subMenu = document.getElementById('sub-menu-' + menuId);
    if (subMenu) {
      if (subMenu.classList.contains('show')) {
        subMenu.classList.remove('show');
        menuItem.classList.remove('expanded');
      } else {
        subMenu.classList.add('show');
        menuItem.classList.add('expanded');
      }
    }
  }

  public closeOtherSubMenus(menu: Array<SidenavMenu>, menuId) {

    const currentMenuItem = menu.filter(item => item.id === menuId)[0];
    menu.forEach(item => {
      if ((item.id !== menuId && item.parentId === currentMenuItem.parentId) ||
        (currentMenuItem.parentId === 0 && item.id !== menuId)) {
        const subMenu = document.getElementById('sub-menu-' + item.id);
        const menuItem = document.getElementById('menu-item-' + item.id);
        if (subMenu) {
          if (subMenu.classList.contains('show')) {
            subMenu.classList.remove('show');
            menuItem.classList.remove('expanded');
          }
        }
      }
    });
  }

}
